import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { initialize, LDClient } from 'launchdarkly-react-client-sdk';
import { Context as AppConfigContext } from 'application/Configuration';
import { ANONYMOUS_USER } from './constants';

type LDClientCacheType = {
  clientCache: { [key: string]: LDClient };
  setClientCache: React.Dispatch<
    React.SetStateAction<{ [key: string]: LDClient }>
  >;
  ldClinicianEmail: string;
  setLDClinicianEmail: React.Dispatch<React.SetStateAction<string>>;
};

export const LDClientCacheContext = createContext<LDClientCacheType>({
  clientCache: {},
  setClientCache: () => {},
  ldClinicianEmail: '',
  setLDClinicianEmail: () => {},
});

export const LDClientCacheProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [clientCache, setClientCache] = useState<{ [key: string]: LDClient }>(
    {}
  );
  const [ldClinicianEmail, setLDClinicianEmail] = useState<string>('');
  return (
    <LDClientCacheContext.Provider
      value={{
        clientCache,
        setClientCache,
        ldClinicianEmail,
        setLDClinicianEmail,
      }}
    >
      {children}
    </LDClientCacheContext.Provider>
  );
};

export const useLDClientCache = () => useContext(LDClientCacheContext);

const LDProviderWrapper = ({ children, clinicianEmail }) => {
  const { LaunchDarklyClientSideId } = useContext(AppConfigContext);
  const { clientCache, setClientCache } = useLDClientCache();
  const [isReady, setIsReady] = useState(false);
  if (!clinicianEmail) {
    clinicianEmail = ANONYMOUS_USER;
  }

  const ldClient = useMemo(() => {
    if (clinicianEmail) {
      if (clientCache[clinicianEmail]) {
        setIsReady(true);
        return clientCache[clinicianEmail];
      } else {
        return initialize(LaunchDarklyClientSideId as string, {
          key: clinicianEmail,
        });
      }
    }
  }, [LaunchDarklyClientSideId, clinicianEmail, clientCache, setIsReady]);

  useEffect(() => {
    if (ldClient && !clientCache[clinicianEmail]) {
      ldClient.waitForInitialization().then(() => {
        setClientCache(prevCache => ({
          ...prevCache,
          [clinicianEmail]: ldClient,
        }));
        ldClient.on('change', () => {
          setClientCache(prevCache => ({ ...prevCache })); // Render the page to reflect new value of the variable
        });
        setIsReady(true);
      });
    }
  }, [ldClient, clinicianEmail, setClientCache, setIsReady, clientCache]);

  if (!isReady) return null;

  return <>{children}</>;
};

export default LDProviderWrapper;
