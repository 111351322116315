import React from 'react';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import { Palette } from '@woundtech/ui-colors';

export type AccordionProps = {
  title?: string;
  time?: string;
  expanded: boolean;
  onChange: (expanded: boolean, event) => void;
  children?: React.ReactNode;
  summaryComponent?: React.FC<any> | null;
  icon?: string;
  removePadding?: boolean;
  variant?: boolean;
  required?: boolean;
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      border: `1px solid ${Palette.Gray.Light}`,
      borderRadius: '4px',
      boxShadow: 'none',
    },
    icon: {
      color: Palette.Blue.Main,
      backgroundColor: Palette.Base.White,
      borderRadius: '50%',
    },
    title: {
      color: Palette.Gray.Darker,
      fontSize: '18px',
      fontWeight: 500,
      margin: '12px 8px',
    },
    titleSecondary: {
      color: Palette.Gray.MediumDarker,
      fontSize: '18px',
      fontWeight: 400,
      margin: '12px 8px',
    },
    summary: {
      borderBottom: `1px solid ${Palette.Gray.Light}`,
      backgroundColor: Palette.Gray.Background,
    },
    details: {
      padding: theme.spacing(2),
    },
    removePadding: {
      padding: '0px',
    },
  };
});

const Accordion: React.FC<AccordionProps> = ({
  title,
  time,
  expanded,
  onChange,
  children,
  summaryComponent: SummaryComponent,
  icon,
  removePadding = false,
  variant = false,
  required,
}) => {
  const classes = useStyles();
  const titleClass = variant ? classes.titleSecondary : classes.title;
  return (
    <ExpansionPanel
      square
      expanded={expanded}
      onChange={(event, expanded) => onChange(expanded, event)}
      className={classes.root}
    >
      <ExpansionPanelSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreRounded className={classes.icon} />}
        className={classes.summary}
      >
        {SummaryComponent ? (
          <SummaryComponent />
        ) : (
          <Box display="flex" flexGrow={1} alignItems="center">
            {!!icon && <Icon color={'disabled'}>{icon}</Icon>}
            <Box width={1} alignSelf="flex-start">
              <Box display="flex" alignItems="center">
                <Typography className={titleClass}>{title}</Typography>
                {required && <Typography color="error">*</Typography>}
              </Box>
            </Box>
            {time && (
              <Box
                width={1}
                alignSelf="flex-end"
                alignItems="right"
                justifyContent="flex-end"
                textAlign={'right'}
              >
                <Typography className={titleClass}>{time}</Typography>
              </Box>
            )}
          </Box>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        className={removePadding ? classes.removePadding : classes.details}
      >
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default React.memo(Accordion);
