export const IMO_WATERMARK = `Powered by IMO® Terminology. © ${new Date().getFullYear()}`;
export const WITH_REFINABLE = '3';
export const REFINABLE_TEXT = 'Refinable';
export const SECONDARYICD10_CODE = 'secondaryIcd10Code';
export const SECONDARYICD10_TEXT = 'secondaryIcd10Text';

export const APPOINTMENT_STATUS_FREE = 'free';
export const ANONYMOUS_USER = 'anonymous';

export const SCHEDULING_LAZY_LOAD_FLAG = 'SchedulingLazyLoadingEnabled';
export const CLINICIAN_ROUTING_FLAG = 'clinicianRoutingEnabled';
export const ENFORCE_CREATE_ROUTE_FLAG = 'enforceCreateRouteEnabled';
export const REMOVE_ROUTE_FLAG = 'removeRouteEnabled';
export const RELEASE_ADMISSION_CONSENT_FLAG = 'release-admission-consent';
export const AVAILABILITY_EXCEPTION_FLAG = 'availabilityException';
export const ROUTE_REPORT_FLAG = 'routeReportEnabled';
export const RE_OPTIMIZE_ROUTE_FLAG = 'reOptimizeRouteButton';
export const SHOW_PATIENT_NAME_ON_MAP_FLAG = 'showPatientNameOnMap';
export const ENCOUNTER_FILTERS_FLAG = 'enableEncounterFilters';
export const INDIVIDUAL_ELIGIBILITY_CHECK_FLAG =
  'individualEligibilityCheckEnabled';
export const OFFLOADING_TREATMENT_MANDATORY_FLAG =
  'release-offloading-treatment';
