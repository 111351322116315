import React from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useLDFlagValue } from 'common/useLDFlagValue';
import { ENCOUNTER_FILTERS_FLAG } from 'common/constants';

type Props = {
  setEnableEncounterStatusFilter?: (value: any) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
    root: {
      height: 38,
      borderRadius: 4,
    },
  })
);

export const EncounterFilterCard: React.FC<Props> = ({
  setEnableEncounterStatusFilter,
}) => {
  const isEnableEncounterFilters = useLDFlagValue(ENCOUNTER_FILTERS_FLAG);
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setValue(value);
    setEnableEncounterStatusFilter &&
      setEnableEncounterStatusFilter(value.length > 0);
  };

  return (
    <>
      {isEnableEncounterFilters && (
        <ToggleButtonGroup value={value} onChange={handleChange}>
          <ToggleButton
            key={'Unsigned'}
            disableRipple
            disableFocusRipple
            value={'Unsigned'}
            classes={{
              selected: classes.selected,
            }}
            className={classes.root}
          >
            Show open encounters
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  );
};
